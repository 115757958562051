import classNames from 'classnames'

import { apolloClient } from 'lib/withApollo'
import APPLY_QUERY from 'graphql/apply.query'

import Link from 'components/Link'
import ApplyLayout from 'components/ApplyLayout'

import s from './apply.module.css'
import graphqlQuery from '../helpers/graphqlQuery';
import isModellinkSite from '../helpers/isModellinkSite';

//TODO: Setup proptypes
function Apply({ image, uiColour, desktopTitle, desktopContent, mobileTitle }) {
  return (
    <ApplyLayout uiColour={uiColour} headerFloat>
      <div className={s.container}>
        <div className={s.mainContent}>
          <div className={s.imageWrapper}>
            <img src={image.sourceUrl} alt="" className={s.image} />
            <div className={s.imageContent}>
              <h1 className={s.title}>{mobileTitle}</h1>
              <Link type="Apply" href="start" className={s.button}>
                Apply
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12.759"
                  height="8.094"
                  viewBox="0.62 -0.327 12.759 8.094"
                  className={s.buttonIcon}
                >
                  <path fill="none" stroke="#ffffff" d="M1 0l5.965 7L13 0" />
                </svg>
              </Link>
            </div>
          </div>
        </div>
        <div className={s.desktopContent}>
          <h1 className={s.title}>{desktopTitle}</h1>
          <div
            className={s.desktopText}
            dangerouslySetInnerHTML={{ __html: desktopContent }}
          />
          <Link type="Apply" href="start" className={s.button}>
            Apply now
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12.759"
              height="8.094"
              viewBox="0.62 -0.327 12.759 8.094"
              className={s.buttonIcon}
            >
              <path fill="none" stroke="#000" d="M1 0l5.965 7L13 0" />
            </svg>
          </Link>
        </div>
      </div>
    </ApplyLayout>
  )
}

export default Apply

export async function getStaticProps({ params }) {
  const uri = isModellinkSite() ? "apply-modellink" : "apply"
  const data = await graphqlQuery(APPLY_QUERY, 'WP_ROOT', {uri})

  return {
    props: {
      ...params,
      ...data.pageBy.acfApplySettings,
    },
    revalidate: 60 * 60,
  }
}
